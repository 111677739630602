import { onUnmounted } from "vue";

export default {
  data() {
    return {
      currentTab: null,
    };
  },

  mounted() {
    this.currentTab = this.$route.query.tab ?? "global";
    this.tabChanged({ title: this.currentTab });
  },

  methods: {
    tabChanged(data) {
      if (this.currentTab !== data.title) {
        const currentQuery = this.$route.query;
        this.$router
          .replace({
            path: this.$route.path,
            query: {
              ...currentQuery,
              tab: data.title,
            },
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              console.error(err);
            }
          });
      }
    },
    closeModal() {},
  },
};
